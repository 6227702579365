export const UserTypeListTableHeader = [
  {
    id: "0",
    label: "Mark",
    value: "_id",
    headPosition: "start",
    checkbox: true,
    sortDisabled: true,
  },
  {
    id: "1",
    label: "User Name",
    value: "full_name",
    headPosition: "start",
  },

  // {
  //   id: "3",
  //   label: "User Type",
  //   value: "user_type",
  //   headPosition: "center",
  // },
  {
    id: "3",
    label: "Email",
    value: "email",
    headPosition: "start",
  },
  {
    id: "03",
    label: "Status",
    value: "status",
    headPosition: "start",
  },
  {
    id: "5",
    label: "Registration Date",
    value: "registered_at",
    headPosition: "start",
  },

  {
    id: "4",
    label: "Last Logon",
    value: "last_login_at",
    headPosition: "start",
  },
  {
    id: "6",
    label: "Cohort ",
    value: "group_name",
    headPosition: "start",
    sortDisabled: true,
  },
  {
    id: "7",
    label: "Days Subscribed ",
    value: "_id",
    headPosition: "start",
    sortDisabled: true,
  },
  {
    id: "8",
    label: "Current Activity Status",
    value: null,
    headPosition: "start",
    sortDisabled: true,
  },
  {
    id: "8",
    label: "Email send",
    value: null,
    headPosition: "start",
    sortDisabled: true,
  },
  {
    id: "9",
    label: "Action",
    value: null,
    headPosition: "center",
    sortDisabled: true,
  },
];
export const AdminTypeListTableHeader = [
  {
    id: "1",
    label: "Employee Number",
    value: "_id",
  },
  {
    id: "2",
    label: "User Name",
    value: "full_name",
  },
  {
    id: "3",
    label: "User Type",
    value: "user_type",
  },

  {
    id: "3a",
    label: "Confirmed",
    value: "is_confirm",
    sortDisabled: true,
  },
  {
    id: "4",
    label: "Email",
    value: "email",
  },
  {
    id: "5",
    label: "Registration Date",
    value: "registered_at",
  },
  {
    id: "6",
    label: "Last Login ",
    value: "updated_at",
  },
  {
    id: "7",
    label: "Action",
    value: null,
    sortDisabled: true,
  },
];
export const FreeUserTypeListTableHeader = [
  {
    id: "1",
    label: "unique ID",
    value: "_id",
  },
  {
    id: "2",
    label: "email",
    value: "email",
  },
  {
    id: "3",
    label: "User Type",
    value: null,
    sortDisabled: true,
  },

  {
    id: "7",
    label: "Action",
    value: null,
    sortDisabled: true,
  },
];

export const QuestionTypeListTableHeader = [
  {
    id: "1",
    label: "Question",
    value: null,
  },
  {
    id: "2",
    label: "Question Type",
    value: null,
  },
  {
    id: "2",
    label: "Permission",
    value: null,
  },
  {
    id: "3",
    label: "Last edited",
    value: null,
  },
  {
    id: "4",
    label: "Action",
    value: null,
  },
  {
    id: "5",
    label: "Set Order",
    value: null,
  },
];

export const SessionTypeListTableHeader = [
  {
    id: "10",
    label: "Internal Name",
    value: "internal_session_name",
  },
  {
    id: "1",
    label: "Session",
    value: "session",
  },
  {
    id: "2",
    label: "Session Type",
    value: "session_type",
  },
  {
    id: "3",
    label: "Category Type",
    value: "category_type",
  },
  {
    id: "4",
    label: "Time",
    value: "time",
  },
  {
    id: "5",
    label: "Last Edited",
    value: "last_updated",
  },
  {
    id: "6",
    label: "Action",
    value: null,
    sortDisabled: true,
  },
];

export const ObjectTypeListTableHeader = [
  {
    id: "1",
    label: "Object",
    value: "object_name",
  },
  {
    id: "2",
    label: "Category Type",
    value: "category_type_name",
  },
  {
    id: "3",
    label: "Object Type",
    value: "object_type_name",
    sortDisabled: true,
  },
  {
    id: "4",
    label: "Tags",
    value: "tag_name",
  },
  {
    id: "7",
    label: "Reps",
    value: "reps",
  },
  {
    id: "5",
    label: "Time",
    value: "created_at",
  },
  {
    id: "6",
    label: "Action",
    sortDisabled: true,
  },
];
export const GroupListTableHeader = [
  {
    id: "1",
    label: "Program Name",
    value: "group_name",
  },
  {
    id: "0",
    label: "Customized Name",
    value: "plan_name",
  },
  {
    id: "2",
    label: "Tag",
    value: "tag_name",
  },
  {
    id: "3",
    label: "Last Updated",
    value: "updated_at",
  },

  {
    id: "6",
    label: "Action",
  },
];

export const UnitTypeListTableHeader = [
  {
    id: "1",
    label: "Unit",
    value: "unit",
  },
  // {
  //   id: "0",
  //   label: "Order",
  //   value: "order",
  // },
  {
    id: "2",
    label: "Category Type",
    value: "category_type",
  },
  {
    id: "3",
    label: "Time",
    value: "time",
  },
  {
    id: "4",
    label: "Last Edited",
    value: "last_update",
  },
  {
    id: "5",
    label: "Action",
    sortDisabled: true,
  },
];

export const Tag1PageListTableHeader = [
  {
    id: "1",
    label: "Tag Name",
    value: "name",
  },
  {
    id: "2",
    label: "User Name",
    value: "user_name",
  },
  {
    id: "3",
    label: "Created Date",
    value: "created_at",
  },
  {
    id: "4",
    label: "Action",
  },
];

export const ReportPageListTableHeader = [
  {
    id: "1",
    label: "Report Info",
    value: "report_info",
  },
  {
    id: "2",
    label: "File",
    value: "file_type",
  },
  {
    id: "3",
    label: "User",
    value: "user",
  },
  {
    id: "4",
    label: "Report Date",
    value: "report_date",
  },
  {
    id: "4",
    label: "Action",
    value: "action",
  },
];
export const AffiliateListTableHeader = [
  {
    id: "1",
    label: "Affiliate Name",
    value: "affiliate_name",
  },
  {
    id: "2",
    label: "Total Subscribers",
    value: "total_subscribers",
  },
  {
    id: "3",
    label: "Total Amount",
    value: "total_amount",
  },
  {
    id: "4",
    label: "Affiliate Link",
    value: "affiliate_link",
  },
  {
    id: "4",
    label: "Action",
    value: null,
  },
];
export const AffiliateDownloadListTableHeader = [
  {
    id: "1",
    headPosition: "start",
    label: "User Name",
    value: "user_name",
  },
  {
    id: "2",
    headPosition: "start",
    label: "Registration Date",
    value: "registered_at",
  },
  {
    headPosition: "start",
    id: "3",
    label: "Plan",
    value: "plan",
  },
];

export const RecallCardListTableHeader = [
  {
    id: "0",
    label: "Order",
    value: "order",
    // sortDisabled: true,
  },
  {
    headPosition: "right",
    id: "0",
    label: "Show",
    value: "_id",
    sortDisabled: true,
  },
  {
    id: "0",
    label: "Name",
    value: "title",
  },
  {
    id: "1",
    label: "Question",
    value: "question_text",
  },
  {
    id: "2",
    label: "Answer",
    value: "answer_text",
  },
  {
    id: "3",
    label: "Tag",
    value: "tagName",
  },
  {
    id: "4",
    label: "Time",
    value: "updated_at",
  },
  {
    id: "5",
    label: "Action",
    value: null,
    sortDisabled: true,
  },
];
export const ReviewListTableHeader = [
  {
    id: "3",
    label: "Date",
    value: "last_update",
  },
  {
    id: "1",
    label: "Title",
    value: "title",
  },
  {
    id: "2",
    label: "User Name",
    value: "user_name",
  },
  {
    id: "3",
    label: "Current Session",
    value: "current_session",
  },
  {
    id: "4",
    label: "Current Unit",
    value: "current_unit",
  },

  {
    id: "5",
    label: "Status",
    value: "status",
  },
  {
    id: "6",
    label: "Action",
    value: null,
  },
];

export const TrophyListTableHeader = [
  {
    id: "1",
    label: "Trophy Name",
  },
  {
    id: "2",
    label: "Trophy Description",
  },
  {
    id: "3",
    label: "Time",
  },
  {
    id: "4",
    label: "Action",
  },
];
export const SwingListTableHeader = [
  {
    id: "1",
    label: "Swing Video Name",
  },
  {
    id: "2",
    label: "Uploaded By",
  },
  {
    id: "3",
    label: "Time",
  },
  {
    id: "4",
    label: "Action",
  },
];

export const RatingTableHeader = [
  {
    id: "1",
    label: "Rating Type",
    value: "type",
  },
  {
    id: "2",
    label: "Date",
    value: "date",
  },
  {
    id: "3",
    label: "Rating",
    value: "rating",
  },
  {
    id: "4",
    value: "text",
    label: "Text",
  },
];
export const SwingReviewTableHeader = [
  {
    id: "1",
    label: "Feedback Type",
    sortDisabled: true,
  },
  {
    id: "2",
    label: "Date",
    value: "date",
  },
  {
    id: "3",
    label: "Result",
    sortDisabled: true,
  },
];

export const CoachReviewTableHeader = [
  {
    id: "1",
    label: "Feedback Type",
    sortDisabled: true,
  },
  {
    id: "2",
    label: "Unit",
    value: "unit",
  },
  {
    id: "3",
    label: "Session",
    value: "session",
  },
  {
    id: "4",
    label: "Result",
    sortDisabled: true,
  },
  {
    id: "5",
    label: "Date",
    value: "date",
  },
  {
    id: "6",
    label: "Status",
    value: "status",
  },
  {
    id: "7",
    label: "Coach Comments",
    sortDisabled: true,
  },
];
export const AddDrillTableHeader = [
  {
    id: "0",
    label: "Select",
    headPosition: "start",
    checkbox: true,
    sortDisabled: true,
  },
  {
    id: "1",
    label: "Drill Name",
    headPosition: "start",
    value: "object_name",
    // sortDisabled: true,
  },
  {
    id: "2",
    label: "Drill Category",
    headPosition: "start",
    sortDisabled: true,
  },
];
export const TicketListTableHeader = [
  {
    id: "1",
    label: "Ticket Number",
    value: "title",
  },
  {
    id: "1n",
    label: "Type",
    value: "title",
  },
  {
    id: "2",
    label: "User Name",
    value: "user_name",
  },
  {
    id: "3",
    label: "Coach Name",
    value: "coach_name",
  },

  {
    id: "4",
    label: "Date Received",
    value: "created_at",
  },
  {
    id: "5",
    label: "Status",
    status: "status",
    sortDisabled: true,
  },
  {
    id: "6",
    label: "View Ticket",
    value: "view_ticket",
    sortDisabled: true,
  },
];
export const TicketListCoachTableHeader = [
  {
    id: "1",
    label: "Ticket Number",
    value: "title",
  },
  {
    id: "1n",
    label: "Type",
    value: "title",
  },
  {
    id: "2",
    label: "User Name",
    value: "user_name",
  },
  {
    id: "3",
    label: "Remaining Hours",
    value: "remaining_hours",
  },

  {
    id: "4",
    label: "Date Received",
    value: "created_at",
  },
  {
    id: "5",
    label: "Status",
    status: "status",
    sortDisabled: true,
  },
  {
    id: "6",
    label: "View Ticket",
    value: "view_ticket",
    sortDisabled: true,
  },
];
export const CoachesListTableHeader = [
  {
    id: "1",
    label: "User name",
    value: "full_name",
  },
  {
    id: "2",
    label: "Total Hours",
    value: "total_hours",
  },
  {
    id: "3",
    label: "Total CompletedTickets ",
    value: "total_completed_tickets",
  },

  {
    id: "4",
    label: "Average Response Time",
    value: "avg_response_time",
  },
  {
    id: "5",
    label: "Registration date",
    value: "registered_at",
  },
  {
    id: "6",
    label: "Last Login ",
    value: "last_login_at",
  },
];

export const ShotCheckReviewTableHeader = [
  {
    id: "1",
    label: "Feedback Type",
    value: "type",
    sortDisabled: true,
  },
  {
    id: "2",
    label: "Date",
    value: "date",
  },
  {
    id: "4",
    label: "Result",
    sortDisabled: true,
  },
];
export const notificationTableHeader = [
  {
    id: "1",
    label: "Title",
    value: "title",
  },
  {
    id: "2",
    label: "Date",
    value: "created_at",
  },
  {
    id: "4",
    label: "Action",
    sortDisabled: true,
  },
];

export const ActivityTableHeader = [
  {
    id: "1",
    label: "Catregory Type",
    value: "category_type",
  },
  {
    id: "2",
    label: "Unit",
    value: "unit_name",
  },
  {
    id: "3",
    label: "Session/Object",
    value: "session_object",
  },
  {
    id: "4",
    label: "Name",
    value: "name",
  },
  {
    id: "5",
    label: "Date Completed",
    value: "date",
  },
];
export const SubscriptionTableHeader = [
  {
    id: "1",
    label: "Order number",
    headPosition: "center",
    value: "transaction_no",
  },
  {
    id: "2",
    label: "Plan",
    headPosition: "start",
    value: "plan",
  },
  {
    id: "3",
    label: "Expiry Date",
    headPosition: "start",
    value: "expiry_date",
  },
  {
    id: "4",
    label: "Price",
    headPosition: "start",
    value: "amount",
  },
  {
    id: "5",
    label: "Status",
    headPosition: "start",
    value: "status",
  },
];
export const FundamentalsTableHeader = [
  {
    id: "1",
    label: "Fundamentals",
    value: "name",
  },
  {
    id: "2",
    label: "Last Edited",
    value: "last_edited",
  },
  {
    id: "3",
    label: "Action",
    value: null,
  },
];

export const DiscountCodesTable = [
  {
    id: "1",
    label: "Plan Name",
    value: "code",
  },
  {
    id: "2",
    label: "Quantity",
    value: "quantity",
  },
  {
    id: "4",
    label: "Used Quantity",
    value: "used_quantity",
  },

  {
    id: "4",
    label: "Created Date",
    value: "created_at",
  },
  {
    id: "3",
    label: "Action",
    value: null,
  },
];

export const AppControlTableHeader = [
  {
    id: "1",
    label: "Major Version",
    value: "version",
  },
  {
    id: "OS",
    label: "OS",
    value: "device_type",
  },
  {
    id: "2",
    label: "Release Date",
    value: "created_at",
  },
  {
    id: "3",
    label: "Version Code",
    value: "version_code",
  },
  {
    id: "force update ",
    label: "Force Update",
    value: "force_update",
  },
  {
    id: "description",
    label: "Description",
    value: "description",
  },

  {
    id: "4",
    label: "Action",
    value: null,
  },
];
